.autoblock-title {
    font-family: $font-serif;
}

.image-text-horizontal {
    h3 {
        font-size: 18px;
    }
}

.image-text-vertical {
    h3 {
        font-weight: bold;
        font-size: 22px;

        .small-col & {
            font-size: 16px;
        }
    }
}

.autoblock-horizontal {
    .abstract {
        font-size: 14px;
    }

    .image-text-horizontal {
        height: 100%;
        padding-top: 0;
    }
}

.small-col {
    .metadata-wrapper {
        margin-bottom: .25rem !important;
        line-height: 1;
    }
}