footer {
    a.navbar-brand {
        display: flex;
        align-items: flex-end;

        &,
        & span {
            &,
            &:hover {
                color: $white !important
            }
        }

        img {
            border-right-color: #736666;
        }

        span {
            font-size: 1.3rem;
        }
    }

    .logo {
        width: 180px;
        margin-bottom: 0 !important;

        @include media-breakpoint-down(md) {
            width: 100px;

            img {
                width: 100px;
            }
        }
    }

    .brand-logo {
        height: 40px;
        width: 544px;
    }

    .brand-logo-md {
        height: 30px;
        width: 190px;
    }

    .paragraph a {
        text-decoration: none;*

        &:hover {
            text-decoration: underline;
        }
    }
}