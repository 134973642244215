.share-social {
    .fb-share-button,
    .twitter-share-btn iframe {
        margin-top: 3px;
    }

    .btn-social-email {
        height: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 600;
        color: $white;
        background: #757575;
        padding: 2px 4px;
        border-radius: 2px;
        margin-top: 3px;

        a {
            color: $white;
        }
    }
}