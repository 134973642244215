/* Custom variables below */

// Override Variables
$font-sans:                     'Open Sans', sans-serif;
$border-color:                  #ddd;

// New Variables
$font-serif:                    'Roboto Slab', serif;
$color-tag:                     #9fafc1;
$theme-mqb:                     #39b54b;
