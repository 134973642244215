.paragraph {
    .rte-text {
        &.aligned {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                display: block;
                background-color: $primary-color;
            }

            &.left {
                padding-left: 2rem;

                &:before {
                    height: 4.5rem;
                    width: 1px;
                    left: 0;
                    top: 0;
                }
            }

            &.right {
                padding-right: 2rem;

                &:before {
                    height: 4.5rem;
                    width: 1px;
                    right: 0;
                    top: 0;
                }
            }

            &.bottom {
                margin-bottom: 2rem;

                &:before {
                    height: 2px;
                    width: 4rem;
                    left: 0;
                    bottom: -12px;
                }
            }

            &.top {
                padding-top: 26px;

                &:before {
                    height: 1px;
                    left: 0;
                    right: 0;
                    top: 0;
                }
            }

            &.quote {
                padding-left: 2rem;
                margin-left: 2rem;
                font-style: italic;
                font-weight: bold;

                &:before {
                    width: 5px;
                    left: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        }

        h2 {
            font-weight: 300;
        }
    }

    ul {
        list-style: none;
        padding-left: 0;

        li {
            position: relative;
            padding-left: 1rem;

            &:before {
                content: '';
                height: 5px;
                width: 5px;
                background-color: $primary-color;
                border-radius: 100%;
                display: block;
                position: absolute;
                left: 0;
                top: 10px;
            }
        }
    }
}

.text-mll-small {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    color: #929292;
}