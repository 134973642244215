
.metadata-time,
.tag a {
    color: $color-tag !important;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.article-header {
    margin-top: 2rem;
}

.article-title {
    h1 {
        font-size: 2rem;
        position: relative;
        margin-bottom: 2rem;

        &:before {
            content: '';
            height: 2px;
            width: 120px;
            display: block;
            background-color: $primary-color;
            position: absolute;
            bottom: -23px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .image-text-horizontal & {
        font-weight: bold;
    }
}

.articlelayout {
    .large-col {
        .rte-text h2 {
            font-family: $font-sans;
            font-weight: 600 !important;
            font-size: 24px;
        }
    }
}