.card-block {
    .image-right {
        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
        }
    }

    .small-col & {
        .card-text p {
            line-height: 1;
        }
    }
}
