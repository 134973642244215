.event {
    &-block {
        padding-top: 28px;
    }

    &-wrapper {
        border: 3px solid $primary-color;
        padding: 1.5rem;
        text-align: center;

        &.event-with-image {
            padding: 3rem;
        }

        &.border-light {
            border: 5px solid $white;
        }
    }

    &-header {
        position: absolute;
        left: 0;
        top: 0;
        background: $primary-color;
        font-weight: 300;
        color: $white !important;
        padding: 12px;
    }

    &-text {
        @include media-breakpoint-up(lg) {
            padding: 0 10rem
        }
    }
}