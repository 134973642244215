.bb-site-wrapper {
    .main {
        padding-top: 167px;

        @include media-breakpoint-down(md) {
            padding-top: 110px;
        }

        @include media-breakpoint-down(sm) {
            padding-top: 80px;
        }
    }
}

a.navbar-brand {
    font-size: 12px;

    &,
    &:hover {
        color: $primary-color !important;
    }

    @include media-breakpoint-down(sm) {
        font-size: 6px;
    }

    img {
        @include media-breakpoint-down(sm) {
            padding-right: .5rem;
            margin-right: .5rem;
        }
    }

    span {
        color: $primary-color !important;
        font-family: $font-serif;
        font-size: 2rem;
        line-height: 1.2;

        @include media-breakpoint-down(sm) {
            font-size: 1.75rem;
        }
    }
}

.brand-logo {
    height: 2.875rem;

    .navbar-brand & {
        width:  34rem;
    }

    @include media-breakpoint-down(sm) {
        height: 2rem;

        .navbar-brand & {
            width: 12.5rem;
        }
    }
}

.outerlink {
    font-size: 14px;
    font-weight: 400;

    img {
        background: $theme-mqb;
        padding: 8px 4px;
        margin-left: .5rem;
        height: 36px;

        @include media-breakpoint-down(md) {
            height: 28px;
        }
    }

    a:hover {
        text-decoration: none;
    }
}

.searchbar-wrapper {
    .input-group {
        border-radius: 2rem;
        border: 2px solid $border-color;
        height: 2.25rem;

        .header-wrapper & {
            @include media-breakpoint-down(sm) {
                border: 0;
            }
        }
    }

    input {
        background: transparent;
        height: 2rem;
        border: 0;
        padding: 0;
        padding-left: 1rem;

        .header-wrapper & {
            padding-left: 0;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    i {
        color: $border-color;
    }

    .search-btn {
        padding: .3rem 1rem;
        border: 0;
    }
}

.navbar-header {
    border-bottom: 0;

    &,
    & .navbar-nav a.nav-link {
        height: 3rem;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
    }

    .sticky & {
        background: $white !important;
    }

    .navbar-primary {
        background: transparent !important;

        @include media-breakpoint-down(md) {
            background-color: $white !important;
        }

        &.navigation-default .submenu li {
            padding: .5rem 1rem;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;
        }
    }

    @include media-breakpoint-down(md) {
        height: 0;
    }

    .navbar-nav a.nav-link:not(.dropdown-toggle):hover {
        text-decoration: none;
    }


    .submenu {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }

        &:hover {
            background-color: $secondary-color !important;
        }

        li {
            &:hover {
                background-color: darken($secondary-color, 10) !important;
            }
        }
    }

    .nav-item.has-children:hover {
        .submenu {
            display: block;
        }
    }
}

.navbar-nav {
    .nav-item {
        .nav-link {
            text-transform: uppercase;
            font-weight: 600;
            padding: 0 .5rem;
            font-size: .875rem;
            position: relative;

            &:after {
                @include media-breakpoint-up(lg) {
                    content: '';
                    background-color: $primary-color;
                    height: 4px;
                    position: absolute;
                    bottom: 1px;
                    @include transition(all 200ms ease-in-out);
                    left: 0;
                    right: 100%;
                }
            }
        }

        &.active,
        &:hover {
            .nav-link {


                &:after {
                    left: 1rem;
                    right: .5rem;
                }
            }
        }
    }
}

.fixed-top {
    border-bottom: 1px solid $secondary-color;
}

.header-wrapper {
    border-bottom: 1px solid $secondary-color;
    box-shadow: 0px 1px 3px 0px $border-color;

    .social-list {
        .list-inline-item {
            margin-right: 0;

            a {
                border: 2px solid $border-color;
            }
        }
    }

    .nav-brand {
        display: none;
        padding: 8px 4px;
        background-color: $primary-color;
        height: 3rem;
    }

    &.sticky {
        .hidden-sticky {
            display: none !important;
        }

        @include media-breakpoint-up(lg) {
            .nav-brand {
                display: block;
            }

            .hidden-md-sticky {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .navbar-header .navbar-primary.navigation-default {
        &.collapse.show, &.collapsing {
            top: 0 !important;
        }
    }
}