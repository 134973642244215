body {
    font-family: $font-sans;
}

h1,
h2,
h3,
h4 {
    font-family: $font-serif;
    line-height: 1.1;
}

p {
    line-height: 1.5;
}

strong {
    font-weight: 600;
}

.vCenter {
    display: flex;
    align-items: center;
}

.btn,
.form-control {
    &:focus {
        outline: none;
        box-shadow: none;
        background: transparent;
    }
}

input,
button,
.btn,
.form-control {
    &:focus {
        outline: none;
        box-shadow: 0;
    }
}

a {
    &,
    &:hover,
    p &:hover,
    h3 &:hover {
        text-decoration: none;
    }
}

// Menu Toggle
.navbar-toggler {
    position: relative;
    border-color: transparent;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    z-index: 1000;
    flex-shrink: 0;
    @include transition($transition-navbar);

    &:focus {
        outline: none;
    }

    .icon-bar {
        position: absolute;
        top: 50%;
        height: 1px;
        left: 50%;
        width: 50%;
        background-color: $navbar-toggler-color;
        @include translate3d(-50%, 0, 0);
        @include transition($transition-navbar);

        &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $navbar-toggler-color;
            border-radius: 1px;
        }
        &:before {
            top: -7px;
        }

        &:after {
            top: 7px;
        }
    }

    &:not(.collapsed) {
        .icon-bar {
            background: transparent !important;

            &:before{
                @include transition($transition-navbar);
                -moz-transform: rotate(45deg) translate(5px,5px);
                -webkit-transform: rotate(45deg) translate(5px,5px);
                -o-transform: rotate(45deg) translate(5px,5px);
                -ms-transform: rotate(45deg) translate(5px,5px);
                transform: rotate(45deg) translate(5px,5px);
            }

            &:after{
                @include transition($transition-navbar);
                -moz-transform: rotate(-45deg) translate(5px,-5px);
                -webkit-transform: rotate(-45deg) translate(5px,-5px);
                -o-transform: rotate(-45deg) translate(5px,-5px);
                -ms-transform: rotate(-45deg) translate(5px,-5px);
                transform: rotate(-45deg) translate(5px,-5px);
            }
        }
    }
}

.cloud-liner {
    &:after {
        border-color: $primary-color;
    }
}

.lead {
    font-size: 1rem;
    font-weight: 400;
}

.row-overlay:after {
    background-color: rgba(0,0,0,.7);
}

.figure.media-image .contentwrapper .above-content-wrapper {
    padding: 0 .5rem;
    background-color: rgba(0,0,0,.2);
}

// Cloud
.is-desktop .cloud-edit.add-padding .navbar,
.is-desktop .cloud-edit.add-padding .navbar .navbar-primary {
    padding-left: 0 !important;
}

.is-desktop .cloud-edit.add-padding .header-wrapper {
    padding-left: 80px;
}

.cloud-ui-wrapper .image-list .item .figure {
    height: 121px !important;
}