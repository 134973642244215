.author-block {
    h3 {
        font-family: $font-sans;
        font-size: 1rem;
        font-weight: 600;
    }

    p {
        font-size: 12px;
    }

    img {
        width: 5rem;
        flex-shrink: 0;
        height: 100%;
    }
}