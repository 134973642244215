/* Custom theme below */

$primary-color:             #dc4c18;
$secondary-color:           #f7f7f6;
$text-color:                #1e1e1e;
$background-color:          #ffffff;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "text": $text-color,
    "white": #fff
)