.mainTitle {
    &-wrapper {
        position: absolute !important;
        z-index: 99;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
    }

    &-content {
        position: absolute;
        bottom: 80px;
        padding: 1rem 1.5rem;
        background-color: rgba($white, .7);
        max-width: 500px;

        @include media-breakpoint-down(md) {
            bottom: 30px;
        }

        h1 {
            font-size: 2rem;
            font-family: $font-serif;
            line-height: 1.2;
            font-weight: 300;
        }

        &:before {
            content: '';
            height: 80px;
            width: 6px;
            display: block;
            background-color: $primary-color;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

}

.block-sliderLanding .owl-carousel .slider-item .figure img {
    @include media-breakpoint-down(md) {
        height: 100%;
        width: auto;
        max-width: unset;
        left: 30%;
    }
}